import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container text-center notFoundPage">
    <h2>NOT FOUND</h2>
    <p>The page you were trying to access cannot be found.</p>
    </div>
  </Layout>
)

export default NotFoundPage
